/* eslint-disable no-unused-vars */
import axios from "axios";
import toasted from "@/core/plugins/vue-toasted";

let cancelTokenSource = null;

const state = {
  profileData: null,
  frontEndLanguages: [],
  backEndLanguages: [],
  qualificationsList: null,
  talentTags: [],
  timeLineData: null,
  clientsList: [],
  commentsList: [],
  commentsMeta: null,
};

const getters = {
  getProfileData(state) {
    return state.profileData;
  },
  getFrontEndLanguages(state) {
    return state.frontEndLanguages;
  },
  getBackEndLanguages(state) {
    return state.backEndLanguages;
  },
  getQualificationsList(state) {
    return state.qualificationsList;
  },
  getTalentTags(state) {
    return state.talentTags;
  },
  getTimeLineData(state) {
    return state.timeLineData?.data;
  },
  getTimeLineMeta(state) {
    return state.timeLineData?.meta;
  },
  getClientsList(state) {
    return state.clientsList;
  },
  getCommentsList(state) {
    return state.commentsList;
  },
  getCommentsMeta(state) {
    return state.commentsMeta;
  },
};

const mutations = {
  setProfileData(state, payload) {
    state.profileData = payload;
  },
  setTalentCv(state, payload) {
    state.profileData.cv = payload;
  },
  setTalentSalary(state, payload) {
    let { part_time_salary, salary } = payload;
    if (part_time_salary !== undefined)
      state.profileData.basicInfo.part_time_salary = +part_time_salary;
    if (salary !== undefined) state.profileData.basicInfo.salary = +salary;
  },
  setTalentAvailability(state, payload) {
    let { part_time_status, full_time_status } = payload;
    if (!isNaN(part_time_status))
      state.profileData.basicInfo.part_time_status = part_time_status;
    if (!isNaN(full_time_status))
      state.profileData.basicInfo.full_time_status = full_time_status;
  },
  setFrontEndLanguages(state, payload) {
    state.frontEndLanguages = payload;
  },
  setBackEndLanguages(state, payload) {
    state.backEndLanguages = payload;
  },
  setTalentExperiences(state, payload) {
    state.profileData.experiences = payload;
  },
  setTalentEducations(state, payload) {
    state.profileData.educations = payload;
  },
  setTalentCertifications(state, payload) {
    state.profileData.certifications = payload;
  },
  setTalentLanguages(state, payload) {
    state.profileData.basicInfo.languages = payload;
  },
  setTalentBio(state, payload) {
    state.profileData.basicInfo.bio = payload;
  },
  setQualificationsList(state, payload) {
    state.qualificationsList = payload;
  },
  toggleQualifications(state, payload) {
    let { technologies, qualified } = payload;
    technologies.forEach((qualification) => {
      let targetTalent = state.qualificationsList.find(
        (item) => item.id === qualification
      );
      targetTalent.qualified = qualified;
    });
  },
  setTalentTags(state, payload) {
    state.talentTags = payload;
  },
  setTimeLineData(state, payload) {
    if (payload && state.timeLineData?.data) {
      state.timeLineData.data.push(...payload.data);
      state.timeLineData.meta = payload.meta;
    } else {
      state.timeLineData = payload;
    }
  },
  setClientsList(state, payload) {
    state.clientsList = payload;
  },
  setCommentsList(state, payload) {
    state.commentsList = payload;
  },
  setCommentsMeta(state, payload) {
    state.commentsMeta = payload;
  },
  // toggleViewQualification(state, payload) {
  //   let targetTalent = state.qualificationsList.find(
  //     (item) => item.id === payload
  //   );
  //   targetTalent.assessment.view_status = targetTalent.assessment.view_status
  //     ? 0
  //     : 1;
  // },
};

const actions = {
  getProfileData({ commit }, payload) {
    return new Promise((resolve) => {
      commit("setProfileData", null);
      axios.get(`users/${payload}`).then((res) => {
        commit("setProfileData", res.data.data);
        resolve("done");
      });
    });
  },

  getProfileDataWithOutLoading({ commit }, payload) {
    return new Promise((resolve) => {
      axios.get(`users/${payload}`).then((res) => {
        commit("setProfileData", res.data.data);
        resolve("done");
      });
    });
  },

  getTalentCv({ commit }, payload) {
    return new Promise((resolve) => {
      axios.get(`users/importCv/${payload}`).then((res) => {
        commit("setTalentCv", res.data.data);
        resolve("done");
      });
    });
  },
  uploadTalentImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`users/uploadImage/${payload.id}`, payload.data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          resolve("done");
          toasted.show("Profile Image Updated Successfully", {
            type: "success",
            icon: "check_circle",
          });
        })
        .catch((err) => {
          err.response?.data?.errors[0] &&
            toasted.show(err.response?.data?.errors[0].error, {
              type: "error",
              icon: "error",
            });
          reject("done");
        });
    });
  },
  uploadTalentCv({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`users/importCv/${payload.id}`, payload.data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          dispatch("getTalentCv", payload.id).then(() => {
            toasted.show("CV Added Successfully", {
              type: "success",
              icon: "check_circle",
            });
            resolve("done");
          });
        })
        .catch(() => {
          reject("done");
        });
    });
  },
  editTalentSalary({ commit, dispatch, getters }, payload) {
    let { id } = payload;
    delete payload.id;
    return new Promise((resolve) => {
      axios.put(`users/salaries/${id}`, payload).then(() => {
        commit("setTalentSalary", payload);
        dispatch("getProfileDataWithOutLoading", id);
        toasted.show("Salary Updated Successfully", {
          type: "success",
          icon: "check_circle",
        });
        resolve("Success");
      });
    });
  },
  editTalentAvailability({ commit }, payload) {
    let { id } = payload;
    delete payload.id;
    return new Promise((resolve) => {
      axios.put(`users/availability/${id}`, payload).then(() => {
        commit("setTalentAvailability", payload);
        toasted.show("Availability Updated Successfully", {
          type: "success",
          icon: "check_circle",
        });
        resolve("Success");
      });
    });
  },
  getBasicInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`users/basicInfo/${payload}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  getFrontEndLanguages({ commit, getters }) {
    return new Promise((resolve) => {
      if (getters.getFrontEndLanguages.length === 0) {
        axios.get("staticContent/frontendLanguages").then((res) => {
          commit("setFrontEndLanguages", res.data.data);
          resolve("done");
        });
      }
      resolve("done");
    });
  },
  getBackEndLanguages({ commit, getters }) {
    return new Promise((resolve) => {
      if (getters.getBackEndLanguages.length === 0) {
        axios.get("staticContent/backendLanguages").then((res) => {
          commit("setBackEndLanguages", res.data.data);
          resolve("done");
        });
      }
      resolve("done");
    });
  },
  EditBasicInfo({ commit }, payload) {
    let { id, data } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`users/personalInfoAndAccounts/${id}`, data)
        .then(() => {
          toasted.show("Profile Updated Successfully", {
            type: "success",
            icon: "check_circle",
          });
          resolve("done");
        })
        .catch((error) => reject(error));
    });
  },
  // talent projects
  addTalentProject({ dispatch }, payload) {
    let { data, talent_id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post("projects/create", data, {
          params: {
            talent_id,
          },
        })
        .then(() => {
          dispatch("getProfileData", talent_id).then(() => {
            resolve("done");
            toasted.show("Project Added Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  editTalentProject({ dispatch }, payload) {
    let { data, talent_id } = payload;
    let projectId = data.id;
    delete data.id;
    return new Promise((resolve, reject) => {
      axios
        .put(`/projects/${projectId}`, data, {
          params: {
            talent_id,
          },
        })
        .then(() => {
          dispatch("getProfileData", talent_id).then(() => {
            resolve("done");
            toasted.show("Project Edited Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  deleteProjectRequest({ dispatch }, payload) {
    let { projectId, talent_id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .delete(`projects/${projectId}?talent_id=${talent_id}`)
        .then(() => {
          dispatch("getProfileData", talent_id).then(() => {
            resolve("done");
            toasted.show("Project Deleted Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  // talent experiences
  getTalentExperiences({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/experiences?talent_id=${payload}`)
        .then((res) => {
          commit("setTalentExperiences", res.data.data);
          resolve("done");
        })
        .catch((error) => reject(error));
    });
  },
  addTalentExperience({ dispatch }, payload) {
    let { data, talent_id, edit, messageDisplayed } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post("/experiences", data, {
          params: {
            talent_id,
          },
        })
        .then(() => {
          dispatch("getTalentExperiences", talent_id).then(() => {
            resolve("done");

            if (!messageDisplayed) {
              toasted.show(
                `Experience ${edit ? "Edited" : "Added"} Successfully`,
                {
                  type: "success",
                  icon: "check_circle",
                }
              );
            }
          });
        })
        .catch((error) => reject(error));
    });
  },
  editTalentExperience({ dispatch }, payload) {
    let { data, talent_id } = payload;
    let experienceId = data.id;
    delete data.id;
    return new Promise((resolve, reject) => {
      axios
        .put(`/experiences/${experienceId}`, data)
        .then(() => {
          dispatch("getTalentExperiences", talent_id).then(() => {
            resolve("done");
            toasted.show("Experience Edited Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  deleteTalentExperience({ dispatch }, payload) {
    let { experienceId, talent_id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .delete(`/experiences/${experienceId}?talent_id=${talent_id}`)
        .then(() => {
          dispatch("getTalentExperiences", talent_id).then(() => {
            resolve("done");
            toasted.show("Experience Deleted Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  // talent educations
  getTalentEducations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/educations?talent_id=${payload}`)
        .then((res) => {
          commit("setTalentEducations", res.data.data);
          resolve("done");
        })
        .catch((error) => reject(error));
    });
  },
  addTalentEducation({ dispatch }, payload) {
    let { data, talent_id, edit, messageDisplayed } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post("/educations", data, {
          params: {
            talent_id,
          },
        })
        .then(() => {
          dispatch("getTalentEducations", talent_id).then(() => {
            resolve("done");
            if (!messageDisplayed) {
              toasted.show(
                `Education ${edit ? "Edited" : "Added"} Successfully`,
                {
                  type: "success",
                  icon: "check_circle",
                }
              );
            }
          });
        })
        .catch((error) => reject(error));
    });
  },
  editTalentEducation({ dispatch }, payload) {
    let { data, talent_id } = payload;
    let educationId = data.id;
    delete data.id;
    return new Promise((resolve, reject) => {
      axios
        .put(`/educations/${educationId}`, data)
        .then(() => {
          dispatch("getTalentEducations", talent_id).then(() => {
            resolve("done");
            toasted.show("Education Edited Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  deleteTalentEducation({ dispatch }, payload) {
    let { educationId, talent_id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .delete(`/educations/${educationId}?talent_id=${talent_id}`)
        .then(() => {
          dispatch("getTalentEducations", talent_id).then(() => {
            resolve("done");
            toasted.show("Education Deleted Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  // talent certificates
  getTalentCertifications({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/certifications?talent_id=${payload}`)
        .then((res) => {
          commit("setTalentCertifications", res.data.data);
          resolve("done");
        })
        .catch((error) => reject(error));
    });
  },
  addTalentCertification({ dispatch }, payload) {
    let { data, talent_id, edit, messageDisplayed } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post("/certifications", data, {
          params: {
            talent_id,
          },
        })
        .then(() => {
          dispatch("getTalentCertifications", talent_id).then(() => {
            resolve("done");
            if (!messageDisplayed) {
              toasted.show(
                `Certification ${edit ? "Edited" : "Added"} Successfully`,
                {
                  type: "success",
                  icon: "check_circle",
                }
              );
            }
          });
        })
        .catch((error) => reject(error));
    });
  },
  editTalentCertification({ dispatch }, payload) {
    let { data, talent_id } = payload;
    let certificationId = data.id;
    delete data.id;
    return new Promise((resolve, reject) => {
      axios
        .put(`/certifications/${certificationId}`, data)
        .then(() => {
          dispatch("getTalentCertifications", talent_id).then(() => {
            resolve("done");
            toasted.show("Certification Edited Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  deleteTalentCertification({ dispatch }, payload) {
    let { certificationId, talent_id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .delete(`/certifications/${certificationId}?talent_id=${talent_id}`)
        .then(() => {
          dispatch("getTalentCertifications", talent_id).then(() => {
            resolve("done");
            toasted.show("Certification Deleted Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  // talent languages
  editTalentLanguages({ commit }, payload) {
    let { data, talent_id } = payload;
    delete data.id;
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/languages/${talent_id}`, data)
        .then(() => {
          commit("setTalentLanguages", data.languages);
          resolve("done");
        })
        .catch((error) => reject(error));
    });
  },
  // talent bio
  editTalentBio({ commit }, payload) {
    let { bio, talent_id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/bio/${talent_id}`, { bio })
        .then(() => {
          commit("setTalentBio", bio);
          resolve("done");
        })
        .catch((error) => reject(error));
    });
  },
  // talent skills
  editTalentSkills({ dispatch, getters }, payload) {
    let { skills, talent_id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/skills/experience/${talent_id}`, { skills })
        .then(() => {
          dispatch("getProfileDataWithOutLoading", talent_id).then((res) => {
            let deleleSkills = getters.getProfileData.basicInfo.skills.filter(
              (skill) => !skills.find((s) => s.skill_id == skill.id)
            );
            if (deleleSkills.length) {
              let technology_ids = deleleSkills.map(
                (deleleSkill) => deleleSkill.id
              );

              let deleleSkillPayload = {
                technology_id: technology_ids,
                talent_id: talent_id,
                is_Edit: true,
              };

              dispatch("deleteTalentSkills", deleleSkillPayload);
            }

            resolve("done");
            toasted.show("Skills Edited Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch((error) => reject(error));
    });
  },
  deleteTalentSkills({ dispatch }, payload) {
    let { technology_id, talent_id, is_Edit } = payload;
    return new Promise((resolve, reject) => {
      axios
        .delete(`/users/skills/experience/${talent_id}`, {
          data: { technology_id: technology_id },
        })
        .then(() => {
          dispatch("getProfileDataWithOutLoading", talent_id).then(() => {
            resolve("done");
            if (!is_Edit) {
              toasted.show("Skills deleted Successfully", {
                type: "success",
                icon: "check_circle",
              });
            }
          });
        })
        .catch((error) => reject(error));
    });
  },
  // talent qualifications
  getQualificationsList({ commit }, payload) {
    return new Promise((resolve) => {
      commit("setQualificationsList", null);
      axios.get(`users/talents/${payload}/skills`).then((res) => {
        commit("setQualificationsList", res.data.data);
        resolve("done");
      });
    });
  },
  toggleQualification({ commit }, payload) {
    let { talent_id, technologies, qualified } = payload;
    commit("toggleQualifications", { technologies, qualified });
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/talents/${talent_id}/toggleQualification`, {
          technologies,
          qualified,
        })
        .then(() => {
          toasted.show("Operation Completed Successfully", {
            type: "success",
            icon: "check_circle",
          });
          resolve("done");
        })
        .catch(() => {
          commit("toggleQualifications", { technologies, qualified });
          reject("done");
        });
    });
  },
  toggleViewQualification({ dispatch }, payload) {
    let { qualificationId, talentId } = payload;
    // commit("toggleViewQualification", qualificationId);
    return new Promise((resolve, reject) => {
      axios
        .put(`/assessments/${qualificationId}/talents/${talentId}/toggleView`)
        .then(() => {
          toasted.show("Operation Completed Successfully", {
            type: "success",
            icon: "check_circle",
          });
          dispatch("getQualificationsList", talentId).then(() => {
            resolve("done");
          });
        })
        .catch(() => {
          // commit("toggleViewQualification", qualificationId);
          reject("done");
        });
    });
  },
  // talent activities
  getTalentTags({ commit }, payload) {
    commit("setTalentTags", []);
    axios.get(`users/talents/${payload}/tags`).then((res) => {
      commit("setTalentTags", res.data.data);
    });
  },
  getTimeLineData({ commit }, payload) {
    let { talentId, page = 1, perPage = 10 } = payload;
    page === 1 && commit("setTimeLineData", null);
    axios
      .get(`/users/talents/${talentId}/activities`, {
        params: {
          page,
          perPage,
          ...payload,
        },
      })
      .then((res) => {
        commit("setTimeLineData", res.data);
      });
  },

  getClientsList({ commit }, payload) {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled due to new request.");
    }

    // Create a new CancelToken source
    cancelTokenSource = axios.CancelToken.source();
    return new Promise((resolve, reject) => {
      commit("setClientsList", []);
      axios
        .get(`clients/projects/preview?search=${payload}`, {
          cancelToken: cancelTokenSource.token,
        })
        .then((res) => {
          commit("setClientsList", res.data.data);
          resolve("done");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setClientsList({ commit }, payload) {
    commit("setClientsList", payload);
  },
  addClientToTalent({ dispatch }, payload) {
    let { client, talentId } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`contracts`, client)
        .then(() => {
          resolve("done");
          dispatch("getProfileDataWithOutLoading", talentId);
          toasted.show("Contract Added Successfully", {
            type: "success",
            icon: "check_circle",
          });
        })
        .catch((error) => reject(error));
    });
  },
  nominateTalentToClient({ dispatch }, payload) {
    let { client, talentId } = payload;
    return new Promise((resolve, reject) => {
      axios
        .post(`/users/talents/${talentId}/nominate`, client)
        .then(() => {
          resolve("done");
          dispatch("getProfileDataWithOutLoading", talentId);
          toasted.show("Talent nominated to the Client Successfully", {
            type: "success",
            icon: "check_circle",
          });
        })
        .catch((error) => reject(error));
    });
  },
  getPricing({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/pricing`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => reject(error));
    });
  },
  updateClientToTalent({ dispatch }, payload) {
    let { contractId, talentId, data } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`contracts/${contractId}`, data)
        .then(() => {
          resolve("done");
          dispatch("getProfileDataWithOutLoading", talentId);
          toasted.show("Contract Edited Successfully", {
            type: "success",
            icon: "check_circle",
          });
        })
        .catch((error) => reject(error));
    });
  },
  deleteClientFromTalent({ dispatch }, payload) {
    let { client_id, talent_id, contract_id } = payload;
    return new Promise((resolve, reject) => {
      const data = {
        client_id,
      };

      axios
        .delete(`contracts/${contract_id}`, {
          data,
        })
        .then(() => {
          resolve("done");
          dispatch("getProfileDataWithOutLoading", talent_id);
          toasted.show("Client Deleted Successfully", {
            type: "success",
            icon: "check_circle",
          });
        })
        .catch((error) => reject(error));
    });
  },
  getNominationHistory({ commit }, payload) {
    let { talent_id, page } = payload;
    return new Promise((resolve) => {
      axios
        .get(
          `/audits?name=talent_nomination&talent=${talent_id}&page=${page}&perPage=10`
        )
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  getTalentContractHistory({ commit }, payload) {
    let { talent_id, page } = payload;
    return new Promise((resolve) => {
      axios
        .get(
          `/contracts?talentId=${talent_id}&page=${page}&perPage=10&order=is_latest`
        )
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  getContractHistory({ commit }, payload) {
    let { talent_id, resource_id, page, logs } = payload;
    return new Promise((resolve) => {
      axios
        .get(
          logs
            ? `/audits?name=talent_contract&resource_id=${resource_id}&relations[]=renewal_terms&relations[]=rejection`
            : `/audits?name=talent_contract&resource_id=${resource_id}`
        )
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  getTalentComments({ commit }, payload) {
    let { talent_id, page = 1 } = payload;
    return new Promise((resolve) => {
      axios.get(`/comments/talent/${talent_id}?page=${page}`).then((res) => {
        commit("setCommentsList", res.data.data);
        commit("setCommentsMeta", res.data.meta);
        resolve(res.data);
      });
    });
  },
  addComment({ dispatch }, payload) {
    let { talent_id, comment, page } = payload;
    let data = { body: comment };
    return new Promise((resolve, reject) => {
      axios
        .post(`/comments/talent/${talent_id}`, data)
        .then(() => {
          dispatch("getTalentComments", { talent_id, page }).then(() => {
            resolve("done");
            toasted.show("Comment Added Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch(() => reject("done"));
    });
  },
  deleteComment({ dispatch }, payload) {
    let { talent_id, comment_id, page } = payload;
    return new Promise((resolve, reject) => {
      axios
        .delete(`/comments/${comment_id}`)
        .then(() => {
          dispatch("getTalentComments", { talent_id, page }).then(() => {
            resolve("done");
            toasted.show("Comment Deleted Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch(() => reject("done"));
    });
  },
  editComment({ dispatch }, payload) {
    let { talent_id, comment_id, comment, page } = payload;
    let data = { body: comment };
    return new Promise((resolve, reject) => {
      axios
        .put(`/comments/${comment_id}`, data)
        .then(() => {
          dispatch("getTalentComments", { talent_id, page }).then(() => {
            resolve("done");
            toasted.show("Comment Edited Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        })
        .catch(() => reject("done"));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
